<script>
export default {
    props: ["page", "scale"],
    computed: {
        actualSizeViewport() {
            return this.viewport.clone({ scale: this.scale });
        },
        canvasStyle() {
            // console.log('viewport', this.actualSizeViewport)
            const {
                width: actualSizeWidth,
                height: actualSizeHeight
            } = this.actualSizeViewport;
            const pixelRatio = window.devicePixelRatio || 1;
            const scale = 1; //(2 * window.innerWidth) / actualSizeWidth;
            const [pixelWidth, pixelHeight] = [
                actualSizeWidth * scale,
                actualSizeHeight * scale
            ].map(dim => Math.ceil(dim / pixelRatio));

            // console.log(
            //     "canvas",
            //     this.actualSizeViewport,
            //     window.innerWidth,
            //     actualSizeWidth,
            //     actualSizeHeight
            // );
            return `width: ${pixelWidth}px; height: ${pixelHeight}px;`;
        },
        canvasAttrs() {
            let { width, height } = this.viewport;
            [width, height] = [width, height].map(dim => Math.ceil(dim));
            const style = this.canvasStyle;
            // console.log(this.viewport, width, height, style)
            return {
                width,
                height,
                style,
                class: "pdf-page"
            };
        },
        pageNumber() {
            return this.page.pageNumber;
        }
    },
    methods: {
        renderPage() {
            // PDFPageProxy#render
            // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
            this.renderTask = this.page.render(this.getRenderContext());
            this.renderTask.promise
                .then(() => this.$emit("rendered", this.page))
                .then(() => console.log(`Page ${this.pageNumber} rendered`));
        },
        destroyPage(page) {
            if (!page) return;
            // PDFPageProxy#_destroy
            // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
            page._destroy();
            // RenderTask#cancel
            // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
            if (this.renderTask) this.renderTask.cancel();
        },
        getRenderContext() {
            const { viewport } = this;
            const canvasContext = this.$el.getContext("2d");
            return { canvasContext, viewport };
        }
    },
    watch: {
        page(page, oldPage) {
            this.destroyPage(oldPage);
        }
    },
    created() {
        // PDFPageProxy#getViewport
        // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
        // console.log(this.page);
        this.viewport = this.page.getViewport({scale: this.scale} );
        // console.log(this.viewport);
    },
    mounted() {
        console.log(`Page ${this.pageNumber} mounted`);
        this.renderPage();
    },
    beforeDestroy() {
        console.log("destroy page");
        // this.destroyPage(this.page);
    },
    render(h) {
        const { canvasAttrs: attrs } = this;
        return h("canvas", { attrs });
    }
};
</script>

<style>
.pdf-page {
    /* width: 100%; */
    height: 100%;
}
</style>
